require("flatpickr");
//import "flatpickr";
import language from 'flatpickr/dist/l10n/es.js';
import "flatpickr/dist/flatpickr.min.css";
//import "flatpickr/dist/themes/material_blue.css";

var default_flatpickr = {
    'locale'		: language.es,
    'allowInput' 	: true,
    'closeOnSelect'	: false,
    'onClose'		: function(selDates, dateStr, instance){
        console.log(selDates);
    }
};

//flatpickr.localize(flatpickr.l10ns.es);
$(() => {
    $('.flatpickr').each(function () {
        var $this = $(this),
        $options = $.extend({}, default_flatpickr, $this.data());
        $this.flatpickr($options);
    });

    $(document).on('app_flatpickr', {}, function(event, element) {
        $(element).find('.flatpickr').each(function () {
            var $this = $(this),
            $options = $.extend({}, default_flatpickr, $this.data());
            $this.flatpickr($options);
        });
    });
    
    // $(document).trigger('app_flatpickr', [$("#intervencion_add_equipo_cont")]);
});