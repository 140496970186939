import './jquery.buttonLoader.js';
import 'jquery.initialize';

/**
 * Funciones utilizadas en las páginas de error de la aplicación.
 */
function reload() { location.reload(true); }
function goback() { window.history.back(); }

(function () {
    console.log('Utilidades.js loaded...');

    $(document).on('change', '.form_inline_index input, .form_inline_index select', function(){
        $(this).closest('form').trigger('submit');
    });

    $(document).on("change", ".sel_unidades_paginator", function () {
        /**
         * Recuperamos del elemento los parametros de GET que se están aplicando ahora para mantenerlos
         */
        var params = $(this).data('params');
        params.limit = $(this).val();
        window.location.href = Routing.generate($(this).data('route'), params);
    });

    $(document).on("change", ".mass_all", function () {
        /**
         * Seleccionar todos los elementos mass de la página dependiendo del estado actual del elemento actual.
         */
        $(this).closest('.mass_form').find('.mass_check').prop('checked', $(this).is(':checked'));
    });

    $(document).on("change", ".mass_select", function () {
        /**
         * Realizar la acción en masa seleccionada comprobando que existan checkbox seleccionados.
         */
        var $this = $(this);
        if ($this.val() != "") {
            if ($this.closest(".mass_form").find("input:checkbox:checked").length > 0) {
                if (confirm($("#trans_mass_sure").val() + ' \n(' + $this.find("option:selected").text() + ')')) {
                    $(".mass_form").trigger('submit');
                } else {
                    $this.val("");
                }
            } else {
                alert($('#trans_mass_no_checked').val());
                $this.val("");
            }
        }
    });

    $(function () {
        /**
         * Tabs bootstrap loading current tab
         */
        var element = document.querySelector('.nav-tabs li:first-child a')

        var hash = location.hash.replace(/^#/, ''); // ^ means starting, meaning only match the first hash
        if (hash) {
            var element = document.querySelector('#' + hash + '-tab');
        }

        if(element){
            //Auto open URL hashed tab on loading page
            new bootstrap.Tab(element).show();

            //Auto scroll on current tab when loading page
            const el = element.parentNode;
            const parent = el.parentNode.parentNode;
            parent.scrollLeft = el.offsetLeft - parent.offsetLeft;
            
        }

        /**
         * Para la correcta visualización de modals cuando se cierra y se abre a la vez alguno
         */
        $('body').on('hidden.bs.modal', function () {
            if ($('.modal.show').length > 0) {
                $('body').addClass('modal-open');
            } else {
                $('body').removeClass('modal-open');
            }
        });
        /**
         * Secundary submit button emulation
         */
        $(document).on('click', '#boto_guardar_cambios', function(){
            $('body').toggleClass('boto_guardar_cambios_clicked', true);
            $(".boto_guardar_cambios_entity").trigger('click');
        });

        /**
         * Open pdf in new window button
         */
        $(document).on('click', "#pdf_link", function (event) {
            event.preventDefault();
            var iframe = "<iframe width='100%' height='100%' src='" + $(this).attr('href') + "'></iframe>"
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
        });

        $('.custom-file-input').on('change', function () {
            var fileName = $(this).val();
            $(this).next('.custom-file-label').addClass("selected text-truncate").html(fileName.replace("fakepath\\", ""));
        });

        /**
         * Loader js
         */
        $(document).on('submit', 'form', function(ev){
        //$("form").submit(function (ev) {
            if ($(ev.currentTarget).find(':submit').hasClass('disabled')) {
                ev.preventDefault();
            }
            $(ev.currentTarget).find(':submit[clicked=true]').buttonLoader('start');
            $(ev.currentTarget).find(':submit').toggleClass('disabled', true);

            if($('body').hasClass('boto_guardar_cambios_clicked')){
                $("#boto_guardar_cambios").attr('data-load-text', 'Guardando');
                $("#boto_guardar_cambios").buttonLoader('start');
            }

            //$(ev.currentTarget).find(':submit').buttonLoader('start');
            //
        });
        $(document).on('click', 'form button:submit', function(){
        //$("form button:submit").click(function () {
            $("button:submit[clicked=true]", $(this).parents("form")).removeAttr("clicked");
            $(this).attr("clicked", true);
        });

        $(document).on('click', '.filtros_eliminar_sidebar', function(){
        //$(".filtros_eliminar_sidebar").on('click', function (event) {
            $(this).attr('data-load-text', 'Limpiando');
            $(this).buttonLoader('start');
        });

        /**
         * Date helpers
         */
        $(document).on('click', ".date-clear", function () {
            if (typeof $(this).closest('.date').find('input:enabled')[0]._flatpickr !== "undefined") {
                $(this).closest('.date').find('input:enabled')[0]._flatpickr.clear();
            } else {
                $(this).closest('.date').find('input:enabled').val('');
            }
        });
        $(document).on('click', ".date-icon", function () {
            $(this).closest('.date').find('input:enabled').focus();
        });


        /**
         * Sidebar output click
         */
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.attributeName === "class") {
                    var attributeValue = $(mutation.target).prop(mutation.attributeName);
                    if (attributeValue.includes('air__sidebar--toggled')) {
                        //Si se está mostrando un sidebar... visualizar el backdrop...
                        $(".sidebar_backdrop_toggle").css('visibility', 'visible');
                        $(".sidebar_backdrop_toggle").css('opacity', '0.2');
                    } else {
                        $(".sidebar_backdrop_toggle").css('visibility', 'hiden');
                        $(".sidebar_backdrop_toggle").css('opacity', '0');
                    }
                }
            });
        });
        observer.observe($("body")[0], {
            attributes: true
        });

        $(document).on('click', '.sidebar_backdrop_toggle', function (ev) {
            $(this).css('visibility', 'hidden');
            $(this).css('opacity', '0');
            $('body').removeClass('air__sidebar--toggled')
        });

        /**
         * Auto resize textarea with class .autosize_custom
         */
        $.initialize(".autosize_custom", function () {
            $(this).height('auto');
            $(this).height($(this).prop('scrollHeight'));
            $(this).on('change drop keydown cut paste', function () {
                $(this).height('auto');
                $(this).height($(this).prop('scrollHeight'));
            });
        });
    });
})();


/**
 * Función para conocer si el tab actual está visible por el usuario...
 */
var vis;
var semaforGlobalVis = false;
window.semaforGlobalVis = global.semaforGlobalVis = semaforGlobalVis;
window.vis = global.vis = vis = (function () {
    var stateKey, eventKey, keys = {
        hidden: "visibilitychange",
        webkitHidden: "webkitvisibilitychange",
        mozHidden: "mozvisibilitychange",
        msHidden: "msvisibilitychange"
    };
    for (stateKey in keys) {
        if (stateKey in document) {
            eventKey = keys[stateKey];
            break;
        }
    }
    return function (Num, c) {
        /**
         * Variable global para poder parar los ajax en determinados casos a parte de cuando no se tiene el foco en el navegador.
         */
        if (global.semaforGlobalVis) {
            return false;
        }

        if (c) document.addEventListener(eventKey, c);
        if (typeof Num !== 'undefined' && Num == 1) {
            return true;
        } else {
            return !document[stateKey];
        }
    }
})();


function CustomInterval(fn, time) {
    var timer = false;
    this.start = function () {
        if (!this.isRunning())
            timer = setInterval(fn, time);
    };
    this.stop = function () {
        clearInterval(timer);
        timer = false;
    };
    this.isRunning = function () {
        return timer !== false;
    };
}

function checkTime(i) {
    if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
    return i;
}

var objectifyForm;
window.objectifyForm = global.objectifyForm = objectifyForm = (function (formArray = {}) {
    var returnArray = {};
    for (var i = 0; i < formArray.length; i++) {
        if (!formArray[i]['name'].includes('[_token]')) {
            returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
    }
    return returnArray;
})();

var elem = document.documentElement;
function openFullscreen() {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
    }
}

function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }

}