import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/js/i18n/es'; 
//import 'select2/dist/css/select2.css';
import 'select2/src/scss/core.scss';
import '../../styles/plugins/js_inc_select2.css';
//import '../../theme/js/vendors/plugins/select2.init';

//import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';


/**
* Modificado de la búsqueda por defecto del plugin para que busque por el titulo de las agrupaciones de select (optgroup) tambien y muestre sus hijos...
**/
var modelMatcher = function modelMatcher (params, data) {
    data.parentText = data.parentText || "";
    if(!params.hasOwnProperty('term')){
        return data;
    }

    if (params.term.trim() === '') {
        return data;
    }

    // Do a recursive check for options with children
    if (data.children && data.children.length > 0) {
        // Clone the data object if there are children
        // This is required as we modify the object to remove any non-matches
        var match = $.extend(true, {}, data);
        // Check each child of the option
        for (var c = data.children.length - 1; c >= 0; c--) {
            var child = data.children[c];
            child.parentText += data.parentText + " " + data.text;
            var matches = modelMatcher(params, child);
            // If there wasn't a match, remove the object in the array
            if (matches == null) {
                match.children.splice(c, 1);
            }
        }
        // If any children matched, return the new object
        if (match.children.length > 0) {
            return match;
        }
        // If there were no matching children, check just the plain object
        return modelMatcher(params, match);
    }
    // If the typed-in term matches the text of this term, or the text from any
    // parent term, then it's a match.
    var original = (data.parentText + ' ' + data.text).toUpperCase();
    var term = params.term.toUpperCase();
    // Check if the text contains the term
    if (original.indexOf(term) > -1) {
        return data;
    }

    if(data.text.indexOf('---') > -1){
        return data;
    }

    // If it doesn't contain the term, don't return anything
    return null;
}

var default_select2 = {
    'matcher'       : modelMatcher,
    'allowClear'    : true,
    'selectionCssClass': ':all:',
    //'containerCssClass': ':all:',
    'width'         : '100%',
    'theme'         : 'bootstrap5',
    "language"      : "es"
};

var default_ajax_select2 = {
    //minimumInputLength  : 3,
    closeOnSelect: false,
    ajax                : {
        //url         :  Routing.generate('aj_datos_l_search_all'),
        dataType    : 'json',
        delay       : 250,
        cache       : false,
        data        : function (params) {
            return {
                q: params.term, // search term
                p: params.page,
                l: params.limit
            };
        },
    }
};

/**
 * Inicializar el select 2 y añadir eventos...
 * @param {Elemento al que inicializar el select} $element 
 * @param {Array de opciones que se le pasan} $options 
 */
function _initSelect2($element, $options){
    $element.select2($options).on('select2:closing', function (e) {
        // Preserve typed value
        if(('NotSaveLastSearch' in $options && $options.NotSaveLastSearch == false) || false == 'NotSaveLastSearch' in $options){
            saveLastSearchQuery(this);
        }
    }).on('select2:open', function (e) {
        // Fill preserved value back into Select2 input field and trigger the AJAX loading (if any)
        if(('NotSaveLastSearch' in $options && $options.NotSaveLastSearch == false) || false == 'NotSaveLastSearch' in $options){
            retrieveLastSearchQuery(this);
        }
        setTimeout(function() {
            //document.querySelector('.select2-container--open .select2-search__field').focus();
        }, 10);
    });
}

/**
* Save last query search on close select
**/
function saveLastSearchQuery(element){
    var currentQuery = $('.select2-search__field').prop('value');
    $(element).data('query', currentQuery);
}
function retrieveLastSearchQuery(element){
    var currentQuery = $(element).data('query');
    //$('.select2-search__field').val(currentQuery).trigger('change').trigger("input");
}

function initSelect2(element){
    var $this = $(element);
    if($this.find("option[value='']").text() != "" && !("placeholder" in element.dataset)){
        default_select2.placeholder = $this.find("option[value='']").text();
    }
    var $options = $.extend({}, default_select2, $this.data());
    _initSelect2($this, $options);
}

function initSelect2Ajax(element){
    var $this = $(element);
    if($this.data('url') != ""){
        var params = (typeof $this.data('urlParams') !== 'undefined' && $this.data('urlParams') != "" ? $this.data('urlParams') : []);
        console.log(params);
        console.log($this.data('url'));
        default_ajax_select2.ajax.url = Routing.generate($this.data('url'), params);
        if($this.find("option[value='']").text() != "" && !("placeholder" in element.dataset)){
            default_select2.placeholder = $this.find("option[value='']").text();
        }
        var $options = $.extend({}, default_select2, default_ajax_select2, $this.data());
        _initSelect2($this, $options);
    }
}

$(() => {
    $('.select_2').each(function () {
        initSelect2(this);
    });

    $('.select_2_ajax:not(.not_init)').each(function () {
        initSelect2Ajax(this);
    });


    $(document).on('app_select_2', {}, function(event, element) {
        $(element).find('.select_2').each(function () {
            initSelect2(this);
        });
    });

    $(document).on('app_select_2_ajax', {}, function(event, element) {
        $(element).find('.select_2_ajax:not(.not_init)').each(function () {
            initSelect2Ajax(this);
        });
    });

    // $(document).trigger('app_select_2', [$("#intervencion_add_equipo_cont")]);
    // $(document).trigger('app_select_2_ajax', [$("#intervencion_add_equipo_cont")]);
});