/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './styles/plugins/loader.css';
import './styles/plugins/buttonLoader.css';

// start the Stimulus application
import './bootstrap';

window.jQuery = window.$ = require('jquery');

window.Popper = require('@popperjs/core');

window.bootstrap = require('bootstrap');



// A lightweight script to animate scrolling to anchor links
window.SmoothScroll = require('smooth-scroll/dist/smooth-scroll.js');

//Clipboard
window.ClipboardJS = require('clipboard/dist/clipboard.min.js');
//const routes = require('../../public/js/fos_js_routes.json');
//import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
//Routing.setRoutingData(routes);
//window.routing = Routing

import '@fortawesome/fontawesome-free/css/all.min.css';
//import '@fortawesome/fontawesome-free/js/all';

import './scripts/utilidades.js';

/** SLIDER JS */
import tns from "../node_modules/tiny-slider/src/tiny-slider";
window.tns = require("../node_modules/tiny-slider/src/tiny-slider");

import './scripts/plugins/js_inc_select2.js';
import './scripts/plugins/js_inc_tinymce.js';
import './scripts/plugins/js_inc_flatpickr.js';
import './scripts/jquery.buttonLoader.js';
import 'ken-burns-carousel';
//import './scripts/plugins/js_inc_summernote.js';
// ES6 Modules or TypeScript
import 'sweetalert2';
//Carrusel de columnes
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import { app } from './bootstrap';
// CommonJS
window.Swal = require('sweetalert2');

//Autocomplete
import 'easy-autocomplete/dist/easy-autocomplete.css';
import 'easy-autocomplete/dist/jquery.easy-autocomplete.js';

$(function () {

    $(document).ready(function(){
        $(".LinkPare").unbind("click").bind('click','span', function(event){
            var slug = event.currentTarget.dataset.slug;
            var url = Routing.generate('pagina_view', { 'PagSlug' : slug})
            window.location.href = url;
        });
    });

    /**
     * Capturar el evento de activar/desactivar el tema oscuro para almacenar en la sesión el valor actual.
     *  - I activar o desactivar el modo oscuro con el html que nos devuelve la petición con la URL del otro tema.
     */

    $(document).on('change', "#kt_user_menu_dark_mode_toggle", function(){
        $.ajax({
            "url"       : Routing.generate('aj_layout_theme_change', { 'dark' : $(this).is(':checked') ? '1' : '0' }),
            "type"      : 'GET',
            'dataType'  : 'html',
            'async'     : true,
            'cache'     : true,
            'timeout'   : 10000,
            'success'   : function(html){
                $('#page_theme').attr('href', $('<div>'+html+'</div>').find('link#page_theme').attr('href'));
            },
            'error'     : function(data){
                console.log(data);
            }
        });
    });

    if($(".recientemente").length > 0){
        $("#kt_activities_loader").show();
        $(".formbuscador").on('click', 'input.historic', function(){
            var url = Routing.generate('aj_his_index');
            $.ajax({
                'url': url,
                'type': "POST",
                'async'     : true,
                'cache'     : true,
                'timeout'   : 10000,
                'success'   : function(html){
                    $('div.hisBodyContent').html(html);
                },
                complete: function(){
                    $("#kt_activities_loader").hide();
                },
                'error'     : function(html){
                    console.log(data);
                    PNotify.error({
                        title: 'Oh No!',
                        text: ' Ha ocurrido un error inesperado recuperando el historial, intentalo de nuevo en unos minutos.'
                    });
                }
            })
        });
    }
});