// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// A theme sis also required
import 'tinymce/themes/silver';
 
/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/save';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/template';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/help';
import 'tinymce/plugins/quickbars';
import 'tinymce-langs/langs/es_ES';
           
var default_tinymce_opt = {
    'selector': 'textarea.tinymce',   
    'plugins': 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
    'toolbar' : 'fullscreen | undo redo | styleselect fontsizeselect fontselect| bold italic underline forecolor  | link image | numlist bullist',
    'quickbars_selection_toolbar': 'bold italic underline | formatselect | forecolor backcolor | quicklink blockquote',
    'language': 'es_ES',
    'convert_urls': false,
} 


function initTinymce(element){
    var extra_opt = {};
    var $this = $(element);
    
    //extrabutton
    var $options = $.extend({}, default_tinymce_opt, $this.data(), extra_opt);
    console.log($options);
    tinymce.init($options);
}

jQuery(function() {
    $('textarea.tinymce').each(function () {
        initTinymce(this);
    });


    $(document).on('app_tinymce', {}, function(event, element) {
        $(element).find('textarea.tinymce').each(function () {
            initTinymce(this);
        });
    });
});